import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Slider from "react-slick";

class Home extends React.Component {
  handleClick(ev) {
    ev.preventDefault();

    document.querySelector(".panel-item.active").classList.remove("active");
    document
      .getElementById(`pee-panel-${ev.currentTarget.dataset.div_id}`)
      .classList.add("active");

    [].forEach.call(
      document.querySelectorAll(".pee-list-actions .arrow"),
      function (el) {
        el.classList.add("d-none");
      }
    );

    ev.currentTarget.parentElement.lastChild.classList.remove("d-none");
  }

  render() {
    var settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      autoplaySpeed: 5000,
    };
    return (
      <div>
        <div className="page-content">
          <div className="pee-carousel">
            <div className="container">
              <Slider {...settings}>
                <div className="pee-carousel-item">
                  <div className="row pee-carousel-item-content">
                    <div className="col-md-5">
                      <div className="col-content">
                        <h2 className="h4 pb-4">
                          <FormattedMessage
                            id="app.page.home.password.bestPasswordGenerator.intro"
                            defaultMessage="Générer vos mot de passe fort"
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id="app.page.home.password.bestPasswordGenerator.text"
                            defaultMessage="Utilisez notre générateur pour générer vos mots de passe"
                          />
                          .
                        </p>
                        <ul className="py-1 list-unstyled ml-1">
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.quick" />
                            </span>
                          </li>
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.securised" />
                            </span>
                          </li>
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.easy" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="col-content">
                        <img
                          className="d-none d-sm-block"
                          src="/images/slider-1.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div className="pee-btn">
                        <p>
                          <Link
                            className="mt-3 btn btn-round"
                            to="/mot-de-passe/generer"
                          >
                            <FormattedMessage
                              id="app.page.home.iGeneratePasswords"
                              defaultMessage="Je génère mes mots de passe"
                            />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pee-carousel-item">
                  <div className="row pee-carousel-item-content">
                    <div className="col-md-5">
                      <div className="col-content">
                        <h2 className="h4 pb-4">
                          <FormattedMessage id="app.page.home.bestCodeGenerator" />
                        </h2>
                        <p>
                          <FormattedMessage id="app.page.home.oneCodeGeneratorSimple" />
                          <br />
                          <FormattedMessage id="app.page.home.oneCodeGeneratorSimple2" />
                        </p>
                        <ul className="py-1 list-unstyled ml-1">
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.quick" />
                            </span>
                          </li>
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.securised" />
                            </span>
                          </li>
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.easy" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="col-content">
                        <img
                          className="d-none d-sm-block"
                          src="/images/slider-1.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div className="pee-btn">
                        <p>
                          <Link
                            className="mt-3 btn btn-round"
                            to="/operation/create"
                          >
                            <FormattedMessage id="app.page.home.iGenerateMyCode" />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pee-carousel-item">
                  <div className="row pee-carousel-item-content ">
                    <div className="col-md-5">
                      <div className="col-content">
                        <h2 className="h4 pb-4">
                          <FormattedMessage id="app.page.home.first1000CodesFree" />
                        </h2>
                        <div>
                          <FormattedMessage id="app.page.home.testOurSolution" />
                        </div>
                        <ul className="py-1 list-unstyled ml-1">
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.withoutTimeLimit" />
                            </span>
                          </li>
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.withoutEngagement" />
                            </span>
                          </li>
                          <li className="py-2">
                            <i
                              className="fa fa-check-circle pr-4"
                              aria-hidden="true"
                            ></i>{" "}
                            <span>
                              <FormattedMessage id="app.page.home.withoutBankingData" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="col-content">
                        <img
                          className="d-none d-sm-block"
                          src="/images/slider-1.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div className="pee-btn">
                        <p>
                          <Link
                            className="mt-3 btn btn-round"
                            to="/operation/create"
                          >
                            <FormattedMessage id="app.page.home.Itest" />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div>
            <div className="container">
              <div className="aa-product-details section" id="features">
                <div className="pee-product-title">
                  <i className="icon-bird" />
                  <FormattedMessage id="app.page.home.features.title" />
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="icon icon-danger">
                      <i className="fa fa-cog" aria-hidden="true" />
                    </div>
                    <div className="description">
                      <div className="h4">
                        <FormattedMessage id="app.page.home.easyToCustomize.title" />
                      </div>
                      <p className="text-muted">
                        <FormattedMessage id="app.page.home.easyToCustomize.text" />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="icon icon-danger">
                      <i className="fa fa-key" aria-hidden="true" />
                    </div>
                    <div className="description">
                      <div className="h4">
                        <FormattedMessage id="app.page.home.constraintUnique.title" />
                      </div>
                      <p className="text-muted">
                        <FormattedMessage id="app.page.home.constraintUnique.text" />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="icon icon-danger">
                      <i className="fa fa-desktop" aria-hidden="true" />
                    </div>
                    <div className="description">
                      <div className="h4">
                        <FormattedMessage id="app.page.home.responsive.title" />
                      </div>
                      <p className="text-muted">
                        <FormattedMessage id="app.page.home.responsive.text" />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="icon icon-danger">
                      <i className="fa fa-file-text" aria-hidden="true" />
                    </div>
                    <div className="description">
                      <div className="h4">
                        <FormattedMessage id="app.page.home.fileOutput.title" />
                      </div>
                      <p className="text-muted">
                        <FormattedMessage id="app.page.home.fileOutput.text" />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="icon icon-danger">
                      <i className="fa fa-life-ring" aria-hidden="true" />
                    </div>
                    <div className="description">
                      <div className="h4">
                        <FormattedMessage id="app.page.home.moreCode.title" />
                      </div>
                      <p className="text-muted">
                        <FormattedMessage id="app.page.home.moreCode.text" />
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="icon icon-danger">
                      <i className="fa fa-bolt" aria-hidden="true" />
                    </div>
                    <div className="description">
                      <div className="h4">
                        <FormattedMessage id="app.page.home.fastGeneration.title" />
                      </div>
                      <p className="text-muted">
                        <FormattedMessage id="app.page.home.fastGeneration.text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <hr />
            </div>

            <div className="container"></div>

            <div className="section">
              <ul className="pee-list-actions">
                <li>
                  <a
                    href="/"
                    className="h4"
                    onClick={this.handleClick}
                    data-div_id="1"
                  >
                    <i
                      className="pee-icon pee-icon-create"
                      aria-hidden="true"
                    />
                    <br />
                    <FormattedMessage id="app.page.home.create" />
                  </a>
                  <i className="arrow" />
                </li>
                <li>
                  <a
                    href="/"
                    className="h4"
                    onClick={this.handleClick}
                    data-div_id="2"
                  >
                    <i
                      className="pee-icon pee-icon-generate"
                      aria-hidden="true"
                    />
                    <br />
                    <FormattedMessage id="app.page.home.generate" />
                  </a>
                  <i className="arrow d-none" />
                </li>
                <li>
                  <a
                    href="/"
                    className="h4"
                    onClick={this.handleClick}
                    data-div_id="3"
                  >
                    <i
                      className="pee-icon pee-icon-download"
                      aria-hidden="true"
                    />
                    <br />
                    <FormattedMessage id="app.page.home.download" />
                  </a>
                  <i className="arrow d-none" />
                </li>
                <li>
                  <a
                    href="/"
                    className="h4"
                    onClick={this.handleClick}
                    data-div_id="4"
                  >
                    <i className="pee-icon pee-icon-key" aria-hidden="true" />
                    <br />
                    <FormattedMessage id="app.page.home.moreCode.show" />
                  </a>
                  <i className="arrow d-none" />
                </li>
                <li>
                  <a
                    href="/"
                    className="h4"
                    onClick={this.handleClick}
                    data-div_id="5"
                  >
                    <i className="pee-icon pee-icon-add" aria-hidden="true" />
                    <br />
                    <FormattedMessage id="app.page.home.moreCode.add" />
                  </a>
                  <i className="arrow d-none" />
                </li>
              </ul>

              <div className="pee-panel">
                <div className="container-fluid">
                  <div id="pee-panel-1" className="panel-item active">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          <FormattedMessage id="app.page.home.slides.createYourProject.title" />
                        </h4>
                        <p>
                          <FormattedMessage id="app.page.home.slides.createYourProject.description1" />
                        </p>
                        <p>
                          <FormattedMessage id="app.page.home.slides.createYourProject.description2" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/create.png" alt="" loading="lazy" />
                      </div>
                    </div>
                  </div>
                  <div id="pee-panel-2" className="panel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          <FormattedMessage id="app.page.home.slides.generateYourCode.title" />
                        </h4>
                        <p>
                          <FormattedMessage id="app.page.home.slides.generateYourCode.description1" />
                        </p>
                        <p>
                          <FormattedMessage id="app.page.home.slides.generateYourCode.description2" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/generate.png" alt="" loading="lazy" />
                      </div>
                    </div>
                  </div>
                  <div id="pee-panel-3" className="panel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          <FormattedMessage id="app.page.home.slides.downloadCodes.title" />
                        </h4>
                        <p>
                          <FormattedMessage id="app.page.home.slides.downloadCodes.description1" />
                        </p>
                        <p>
                          <FormattedMessage id="app.page.home.slides.downloadCodes.description2" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/download.png" alt="" loading="lazy" />
                      </div>
                    </div>
                  </div>
                  <div id="pee-panel-4" className="panel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          <FormattedMessage id="app.page.home.slides.showProject.title" />
                        </h4>
                        <p>
                          <FormattedMessage id="app.page.home.slides.showProject.description1" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/access.png" alt="" loading="lazy" />
                      </div>
                    </div>
                  </div>
                  <div id="pee-panel-5" className="panel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <h4>
                          <FormattedMessage id="app.page.home.slides.add.title" />
                        </h4>
                        <p>
                          <FormattedMessage id="app.page.home.slides.add.description1" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <img src="/images/add.png" alt="" loading="lazy" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <hr />
            </div>

            <div className="pee-price container section">
              <div className="row pee-col-group">
                <div className="pee-col col-md-6">
                  <div className="pee-col-content">
                    <div className="pee-col-body">
                      <div className="title h2">0 &euro;</div>
                      <p>
                        <FormattedMessage id="app.page.home.1000CodesOfferedToTest" />
                      </p>

                      <ul className="mt-4">
                        <li>
                          <FormattedMessage id="app.page.home.withoutTimeLimit" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.withoutEngagement" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.withoutBankingData" />
                        </li>
                      </ul>
                    </div>
                    <div className="pee-col-footer">
                      <div className="pee-btn text-center">
                        <a
                          className="mt-4 btn btn-outline-neutral"
                          href="/operation/create"
                        >
                          <FormattedMessage id="app.page.home.ToPeeck" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pee-col col-md-6">
                  <div className="pee-col-content">
                    <div className="pee-col-body">
                      <div className="title h1">0.01 &euro;</div>
                      <p>
                        <FormattedMessage id="app.page.home.10000CodesFor1cte" />
                      </p>

                      <ul className="mt-4">
                        <li>
                          <FormattedMessage id="app.page.home.withoutTimeLimit" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.withoutEngagement" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.securedPayment" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.availableImmediately" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.operationUnlimited" />
                        </li>
                      </ul>
                    </div>
                    <div className="pee-col-footer">
                      <div className="pee-btn text-center">
                        <a
                          className="mt-4 btn btn-outline-neutral"
                          href="/code/get-codes"
                        >
                          <FormattedMessage id="app.page.home.ToPeeck" />
                        </a>
                      </div>
                      <i className="icon-bird" />
                      <p>
                        * <FormattedMessage id="app.page.home.withoutFee" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pee-col col-md-6">
                  <div className="pee-col-content">
                    <div className="pee-col-body">
                      <div className="title h1">1 &euro;</div>
                      <p>
                        <FormattedMessage id="app.page.home.1MillionCodesFor1Euro" />
                      </p>

                      <ul className="mt-4">
                        <li>
                          <FormattedMessage id="app.page.home.withoutTimeLimit" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.withoutEngagement" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.securedPayment" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.availableImmediately" />
                        </li>
                        <li>
                          <FormattedMessage id="app.page.home.operationUnlimited" />
                        </li>
                      </ul>
                    </div>
                    <div className="pee-col-footer">
                      <div className="pee-btn text-center">
                        <a
                          className="mt-4 btn btn-outline-neutral"
                          href="/code/get-codes/100"
                        >
                          <FormattedMessage id="app.page.home.ToPeeck" />
                        </a>
                      </div>
                      <i className="icon-bird" />
                      <p>
                        * <FormattedMessage id="app.page.home.withoutFee" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pee-col col-md-6">
                  <div className="pee-col-content">
                    <div className="pee-col-body">
                      <div className="title h3">
                        <FormattedMessage id="app.page.home.wePeekMore" />
                      </div>
                      <p>
                        <FormattedMessage id="app.page.home.wePeekMoreText" />
                      </p>
                      <img src="/images/codes.png" alt="" loading="lazy" />
                    </div>
                    <div className="pee-col-footer">
                      <div className="pee-btn text-center">
                        <a
                          className="mt-4 btn btn-outline-neutral"
                          href="/code/get-codes"
                        >
                          <FormattedMessage id="app.page.home.ToPeeck" />
                        </a>
                      </div>
                      <div className="pee-icon-container">
                        <i className="icon-bird" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <hr />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3>
                    <FormattedMessage
                      id="app.page.home.createRandomMessage.title"
                      defaultMessage="Créer des codes uniques et aléatoires instantanément"
                    />
                  </h3>
                  <FormattedMessage
                    id="app.page.home.createRandomMessage.text"
                    defaultMessage="Créer votre compte CodePeecker® afin de générer des codes aléatoires et uniques en temps réel. Vous pouvez à tout moment rajouter des codes uniques sans aucune contrainte. Créer votre compte gratuitement."
                  />
                  <h3>
                    <FormattedMessage
                      id="app.page.home.connectedFavoriteTools.title"
                      defaultMessage="Connectez vos outils favoris"
                    />
                  </h3>
                  <FormattedHTMLMessage
                    id="app.page.home.connectedFavoriteTools.text"
                    defaultMessage="Gérez la création et la génération des codes aléatoires et uniques pour tous vos canaux de distribution en un seul endroit. Découvrez l'API CodePeecker®."
                  />
                  <h3>
                    <FormattedMessage
                      id="app.page.home.expendUniqueCodeCreationOnYourCompany.title"
                      defaultMessage="Développez la création des codes uniques pour votre entreprise"
                    />
                  </h3>
                  <FormattedHTMLMessage
                    id="app.page.home.expendUniqueCodeCreationOnYourCompany.text"
                    defaultMessage="Faites évoluez vos campagnes de marketing produits ou vos opérations de création de codes en masse de façon unique et  automatisée grâce à des puissants workflows imaginés et conçus  par nos équipes d'ingénieur."
                  />
                  <h3>
                    <FormattedMessage
                      id="app.page.home.manageCampaign.title"
                      defaultMessage="Gérez vos campagnes de création de code uniques"
                    />
                  </h3>
                  <FormattedHTMLMessage
                    id="app.page.home.manageCampaign.text"
                    defaultMessage="Retrouvez à tout moment l’ensemble des paramètres de vos campagnes de création de code unique et aléatoire. Chez  CodePeecker® vos coupons de réduction, codes promos, flash  code, Qr Code, mots de passe ou codes pour les cartes  cadeaux/carte de fidélité n’ont aucune durée d’expiration."
                  />
                </div>
                <div className="col-md-6">
                  <h2 className="title">
                    <FormattedMessage
                      id="app.page.home.whatYouCanDo.title"
                      defaultMessage="Ce que vous pouvez faire avec CodePeecker®"
                    />
                  </h2>
                  <FormattedHTMLMessage
                    id="app.page.home.whatYouCanDo.text"
                    defaultMessage="Chez CodePeecker®, notre interface ludique vous aide à vous  concentrer sur la croissance de votre entreprise. Commencez  par créer votre opération de création de code à multiples  usages (coupon de réduction, code promo, flash code, Qr Code,  mot de passe, codes pour les cartes cadeaux ou carte de  fidélité...).<br /><br />Générez ensuite vos codes uniques et aléatoires en temps réel et sans délais d'attente. Vous l'aviez demandé, CodePeecker® vous permet désormais de séparer par fichier les codes générés et cela complètement de façon très fluide et sans intervention manuelle.<br /><br />Tous les codes uniques et aléatoires créés sont immédiatement téléchargeables en 1 clic. Ceci est très utile pour vos campagnes de génération de code nationales ou internationales.  Commencez dès aujourd'hui gratuitement."
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <hr />
            </div>

            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="title">
                    <FormattedMessage
                      id="app.page.home.yourCodeGenerationSoftware.title"
                      defaultMessage="CodePeecker® votre logiciel de génération de code, coupon et plus encore…"
                    />
                  </h2>
                  <FormattedHTMLMessage
                    id="app.page.home.yourCodeGenerationSoftware.text"
                    defaultMessage="Créez rapidement des campagnes personnalisées de génération de code promo, code de réduction, codes carte de fidélité ou carte cadeaux.<br />Lancez vos campagnes de création de code unique sans abonnement mensuel et bénéficiez immédiatement de 10 000 codes uniques offerts.<br />La génération des codes uniques & aléatoires est un processus très compliqué, stratégique et si important qu'il doit être  automatisé et facile pour tout le monde.<br />Découvrez à quel point il est simple de créer et générer des codes uniques et aléatoires grâce à CodePeecker®."
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <hr />
            </div>

            <div className="aa-testimonials-section section">
              <div className="container">
                <div className="text-center">
                  <h2 className="title mt-0">
                    <FormattedMessage id="app.page.home.whatOurCustomersAreSaying" />
                  </h2>
                </div>
                <div className="aa-testimonials">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="aa-testimonials-body">
                        <p>
                          Un logiciel remarquable et un service de conseil
                          compétent. CodePeecker® réintroduit l’agilité dans
                          notre structure et nous pousse à fonctionner comme une
                          startup Dynamique. CodePeecker® nous a également aidé
                          dans la rationalisation de notre processus complexe de
                          génération unique des codes de carte fidélité.
                        </p>
                        <div className="row pt-3">
                          <div className="col-lg-5 col-md-12"></div>
                          <div className="col-lg-7 col-md-12 pt-3">
                            <div className="h5">Stéphanie Boudoires</div>
                            <p className="text-muted">CARADOR</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="aa-testimonials-body">
                        <p>
                          CodePeecker® m’a permis de gagner un temps de
                          développement précieux. Ceci m’a permis de me
                          focaliser et consacrer plus d’énergie à mon produit.
                          Il m’a fallu littéralement 1 minute pour générer mes
                          codes promos uniques.
                        </p>
                        <div className="row pt-3">
                          <div className="col-lg-5 col-md-12"></div>
                          <div className="col-lg-7 col-md-12 pt-3">
                            <div className="h5">Nicolas Tokatlian</div>
                            <p className="text-muted">SUGAR</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="aa-testimonials-body">
                        <p>
                          Nous utilisons CodePeecker® pour créer et générer des
                          codes promos pour l’ensemble de nos jeux concours
                          magasins. C’est simple d’utilisation et la génération
                          des codes promos se fait en temps réel.
                        </p>
                        <div className="row pt-3">
                          <div className="col-lg-5 col-md-12"></div>
                          <div className="col-lg-7 col-md-12 pt-3">
                            <div className="h5">Magali</div>
                            <p className="text-muted">Rose & Marius</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="aa-testimonials-body">
                        <p>
                          CodePeecker is a game changer, millions of uniques
                          codes generated in minutes with an awesome intuitive
                          interface. Many options to generate my codes in an
                          effective and easy way. Definitely recommend it.
                        </p>
                        <div className="row pt-3">
                          <div className="col-lg-5 col-md-12">
                            <img
                              className="img-fluid"
                              src="/images/gpeg.jpeg"
                              alt="Face 1"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-7 col-md-12 pt-3">
                            <div className="h5">Gary Pegeot</div>
                            <p className="text-muted">Lead Developer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="aa-testimonials-body">
                        <p>
                          CodePeecker is awesome and I can't imagine working on
                          my projects without it. It has saved me countless
                          hours of time and has increased my productivity.
                        </p>
                        <div className="row pt-3">
                          <div className="col-lg-5 col-md-12">
                            <img
                              className="img-fluid"
                              src="/images/kser.jpeg"
                              alt="Face 2"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-lg-7 col-md-12 pt-3">
                            <div className="h5">Kevin Serrat</div>
                            <p className="text-muted">Web Developer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
