import { RouteNotFoundError } from "./RouteNotFoundError";
import { generatePath } from "react-router";

export interface Path {
  locale: string;
  path: string;
}

export interface Route {
  name: string;
  paths: Map<string, Path>;
}

export interface Routes {
  routes: Map<string, Route>;
}

export interface RouteObject {
  name: string;
  paths: Path[];
}

interface RoutesObject {
  routes: RouteObject[];
}

export default class RouteLoader {
  #routes: Map<string, Route>;

  constructor(routes: RoutesObject) {
    let mapRoutes = new Map<string, Route>();
    routes.routes.forEach(function (routeObject: RouteObject) {
      let mapPaths = new Map<string, Path>();
      routeObject.paths.forEach(function (path: Path) {
        mapPaths.set(path.locale, path);
      });

      mapRoutes.set(routeObject.name, {
        name: routeObject.name,
        paths: mapPaths,
      });
    });

    this.#routes = mapRoutes;
  }

  getName(path: string): string {
    for (const route of this.#routes.values()) {
      for (const value of route.paths.values()) {
        if (value.path === path) {
          return route.name;
        }
      }
    }

    throw new RouteNotFoundError(`Route not found with path ${path}`);
  }

  path(
    name: string,
    locale: string,
    params?: { [key: string]: string }
  ): string {
    const route = this.#routes.get(name);
    if (!route) {
      throw new RouteNotFoundError(`Route ${name} not found`);
    }

    const path = route.paths.get(locale);

    if (!path) {
      throw new RouteNotFoundError(
        `Route ${name} not found for locale ${locale}`
      );
    }

    return generatePath(path.path, params);
  }

  paths(name: string): string[] {
    const route = this.#routes.get(name);
    if (!route) {
      throw new RouteNotFoundError(`Route ${name} not found`);
    }

    return Array.from(route.paths.values()).map((path) => path.path);
  }
}
