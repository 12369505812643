import React from "react";

import { alertActions } from "../../Actions";
import Input from "../Form/Input";
import Form from "../../Form";
import { history, store } from "../../Helper";
import ModelManager from "../../ModelManager";
import { userService } from "../../Service";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export class GetNewPasswordForm extends Form {
  constructor(props) {
    const element = {
      "@id": "/api/request_password_histories",
      email: "",
    };

    super(props, element);
  }

  submit(e) {
    e.preventDefault();

    let violations = [];

    if (this.state.element.email === "") {
      violations.push({
        propertyPath: "email",
        message: "form.error.emailRequired",
      });
    }

    if (violations.length === 0) {
      ModelManager.save(this.state.element)
        .then((element) => {
          if (!userService.isAuthenticated()) {
            history.push("/login");
          }
          store.dispatch(alertActions.success("app.message.emailSent"));
        })
        .catch((error) => {
          error.violations
            ? this.mapErrors(error.violations)
            : this.cleanErrors();
        });
    } else {
      this.mapErrors(violations);
    }
  }

  render() {
    return (
      <div className="container">
        <div className="page-content">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <FormattedMessage id="app.page.getNewPasswordForm.home" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <FormattedMessage id="app.page.getNewPasswordForm.changePassword" />
              </li>
            </ol>
          </nav>

          <h1>
            <FormattedMessage id="app.page.getNewPasswordForm.changePassword" />
          </h1>

          <form id="login-form" noValidate>
            <div className="form-row">
              <div className="form-group col-md-12">
                <Input
                  name="email"
                  label="form.label.email"
                  ref="email"
                  type="email"
                  value={this.state.element.email}
                  errors={this.state.errors.email}
                  updateElement={this.updateElement}
                  required={true}
                />
                <small>
                  <FormattedMessage id="app.page.getNewPasswordForm.enterYourEmail" />
                  .
                </small>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block"
              onClick={this.submit}
            >
              <FormattedMessage id="app.page.getNewPasswordForm.register" />
            </button>

            <small className="form-text text-muted">
              (*){" "}
              <FormattedMessage id="app.page.getNewPasswordForm.fieldRequired" />
              .
            </small>
          </form>
        </div>
      </div>
    );
  }
}
