import { API_URL, getHeaders, getPromise } from "./ModelManager";

export default class UserModelManager {
  /**
   * Get connected user.
   *
   * @returns {Promise}
   */
  static getConnectedUser() {
    const url = `${API_URL}/api/users/me`;

    return getPromise(url, { headers: getHeaders() }, false, false);
  }

  /**
   * confirm user.
   *
   * @returns {Promise}
   */
  static confirmUser(confirmationToken) {
    const url = `${API_URL}/active/${confirmationToken}`;

    return getPromise(url, { headers: getHeaders() }, false, false);
  }
}
