import React from "react";
import Input from "./Input";
import FormattedMessageFixed from "../../FormattedMessageFixed";

class Select extends Input {
  render() {
    const options = this.props.options
      ? this.props.options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))
      : "";

    return (
      <React.Fragment>
        <label>
          <FormattedMessageFixed id={this.props.label} />
          {this.props.required ? "*" : ""}
        </label>

        <select
          className={this.getClassName()}
          placeholder={this.props.placeholder}
          name={this.props.name}
          id={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          required={this.props.required}
        >
          {options}
        </select>

        <div className="invalid-feedback">
          <ul className="errors">
            {this.hasError()
              ? this.state.errors.map((error, index) => {
                  return (
                    <li key={index + 1}>
                      <FormattedMessageFixed id={error} />
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Select;
