import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

export default class Me extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      redirect: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      user: props.user,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user.email}{" "}
        <span className="codeCount">
          <FormattedNumber value={this.props.user.codeRemaining} />{" "}
          <FormattedMessage id="app.page.repartitionList.codesRemaining" />
        </span>
      </React.Fragment>
    );
  }
}
