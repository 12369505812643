import React from "react";
import { Route } from "react-router-dom";
import { userService } from "../Service";

class PrivateComponent extends React.Component {
  render() {
    return userService.isAuthenticated() ? <Route {...this.props} /> : "";
  }
}

export default PrivateComponent;
