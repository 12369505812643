import React from "react";
import { encodingOptions, eofOptions } from "../../Model/Operation";
import { Redirect } from "react-router";
import ElementForm from "../../Form";
import Input from "../Form/Input";
import Select from "../Form/Select";
import ModelManager from "../../ModelManager";
import { alertActions } from "../../Actions";
import { store } from "../../Helper";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import FormattedHTMLMessageFixed from "../../FormattedHTMLMessageFixed";

class OperationForm extends ElementForm {
  constructor(props) {
    const operation = {
      "@id": "/api/operations",
      id: null,
      name: "",
      login: "",
      encoding: encodingOptions[0].id,
      endOfLine: eofOptions[0].id,
      characterNumber: 0,
      codeNumber: 0,
      characterList: "ABCEFGHJKMNPRSTWXY3456789",
    };

    const requiredFields = [
      "name",
      "login",
      "endOfLine",
      "characterNumber",
      "codeNumber",
      "characterList",
    ];

    super(props, operation, requiredFields);
  }

  submit(e) {
    e.preventDefault();

    let violations = [];

    for (let requiredField of this.state.requiredFields) {
      if (
        this.state.element[requiredField] === "" ||
        this.state.element[requiredField] === null
      ) {
        violations.push({
          propertyPath: requiredField,
          message: "form.error.notBlank",
        });
      }
    }

    let operation = { ...this.state.element };
    operation.encoding = parseInt(operation.encoding, 0);
    operation.endOfLine = parseInt(operation.endOfLine, 0);

    if (violations.length === 0) {
      ModelManager.save(operation)
        .then((element) => {
          this.setState({ element });
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          store.dispatch(alertActions.error("form.error.anErrorOccured"));
          error.violations
            ? this.mapErrors(error.violations)
            : this.cleanErrors();
        });
    } else {
      window.scrollTo(0, 0);
      store.dispatch(alertActions.error("form.error.anErrorOccured"));
      this.mapErrors(violations);
    }
  }

  getCodeProbability(codeLength, characterList, codeNumber) {
    if (
      !(codeNumber > 0) ||
      !characterList ||
      characterList.length === 0 ||
      !(codeLength >= 1)
    ) {
      return false;
    }

    let count = 1;
    for (let i = 0; i < codeLength; i++) {
      count *= characterList.length;
    }

    return count / codeNumber;
  }

  render() {
    if (this.state.element.id) {
      return (
        <div>
          <p>Vous allez être redirigé vers votre opération.</p>
          <Redirect to={`/operation/${this.state.element.id}`} />
        </div>
      );
    }

    let codeProbability = this.getCodeProbability(
      this.state.element.characterNumber,
      this.state.element.characterList,
      this.state.element.codeNumber
    );
    let proba = Math.round(codeProbability);

    return (
      <div className="container">
        <div className="page-content">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <FormattedMessage id="app.page.operationForm.home" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <FormattedMessage id="app.page.operationForm.createOperation" />
              </li>
            </ol>
          </nav>

          <h1>
            <FormattedMessage id="app.page.operationForm.createOperation" />
          </h1>

          <form noValidate className="operationForm">
            <div className="form-group">
              <Input
                name="name"
                label="form.label.name"
                ref="name"
                type="text"
                errors={this.state.errors.name}
                updateElement={this.updateElement}
                value={this.state.element.name}
                required={this.isRequired("name")}
              />
              <small id="emailHelp" className="form-text text-muted">
                <FormattedMessage id="app.page.operationForm.name.description" />
                .
              </small>
            </div>

            <div className="form-group">
              <Input
                name="login"
                label="form.label.login"
                ref="login"
                type="text"
                errors={this.state.errors.login}
                updateElement={this.updateElement}
                value={this.state.element.login}
                required={this.isRequired("login")}
              />
              <small id="emailHelp" className="form-text text-muted">
                <FormattedMessage id="app.page.operationForm.login.description" />
              </small>
            </div>

            <div className="form-group">
              <Select
                placeholder="End of line"
                name="endOfLine"
                label="form.label.endOfLine"
                ref="endOfLine"
                options={eofOptions}
                value={this.state.element.endOfLine}
                errors={this.state.errors.endOfLine}
                updateElement={this.updateElement}
                required={this.isRequired("endOfLine")}
              />
              <small id="emailHelp" className="form-text text-muted">
                <FormattedHTMLMessageFixed id="app.page.operationForm.endOfLine.description" />
              </small>
            </div>

            <div className="form-group">
              <Input
                name="characterNumber"
                label="form.label.characterNumber"
                ref="characterNumber"
                type="number"
                errors={this.state.errors.characterNumber}
                value={this.state.element.characterNumber}
                updateElement={this.updateElement}
                required={this.isRequired("characterNumber")}
              />
              <small id="emailHelp" className="form-text text-muted">
                <FormattedMessage id="app.page.operationForm.characterNumber.description" />
              </small>
            </div>

            <div className="form-group">
              <Input
                name="codeNumber"
                label="form.label.totalCode"
                ref="codeNumber"
                type="number"
                errors={this.state.errors.codeNumber}
                value={this.state.element.codeNumber}
                updateElement={this.updateElement}
                required={this.isRequired("codeNumber")}
              />
              <small id="emailHelp" className="form-text text-muted">
                <FormattedMessage id="app.page.operationForm.codeNumber.description" />
              </small>
            </div>

            <div className="form-group">
              <Input
                name="characterList"
                label="form.label.characterCodeAllowed"
                value={this.state.element.characterList}
                ref="characterList"
                type="text"
                errors={this.state.errors.characterList}
                updateElement={this.updateElement}
                required={this.isRequired("characterList")}
              />
              <small id="emailHelp" className="form-text text-muted">
                <FormattedMessage id="app.page.operationForm.characterList.description" />
              </small>
            </div>

            {codeProbability !== false && codeProbability > 1 ? (
              <p>
                <FormattedMessage id="app.page.operationForm.proba" />{" "}
                <b>1 / {proba}.</b>
              </p>
            ) : (
              ""
            )}

            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.submit}
            >
              <FormattedMessage id="app.page.operationForm.create" />
            </button>
            <small className="form-text text-muted">
              (*) <FormattedMessage id="app.page.operationForm.fieldRequired" />
              .
            </small>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const connectedOperationForm = connect(mapStateToProps)(OperationForm);
export { connectedOperationForm as OperationForm };
