import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import PrivateComponent from "../PrivateComponent";
import PublicComponent from "../PublicComponent";
import Me from "../User/Me";
import { FormattedMessage } from "react-intl";
import { RouteContext } from "../../Router/RouteContext";
import { useDispatch, useSelector } from "react-redux";
import { updateIntl } from "react-intl-redux";
import messages_fr from "../../Translations/fr.json";
import messages_en from "../../Translations/en.json";
import { userService } from "../../Service";
import { Modal } from "react-bootstrap";
import { LoginForm } from "../Login/LoginForm";
import { userActions } from "../../Actions";
import { generatePath } from "react-router";
import FormattedMessageFixed from "../../FormattedMessageFixed";

const localData = {
  fr: messages_fr,
  en: messages_en,
};

export default function Header() {
  const router = useContext(RouteContext);
  const intl = useSelector((state) => state.intl);
  const user = useSelector((state) => state.user);
  const [locale, setLocale] = useState(intl.locale);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  function currentPathWithLocale(locale) {
    try {
      return router.path(router.getName(location.pathname), locale);
    } catch (e) {
      console.warn(e);

      return generatePath(location.pathname, params);
    }
  }

  useEffect(() => {
    dispatch(
      updateIntl({
        locale,
        messages: localData[locale],
      })
    );
  }, [locale, dispatch]);

  return (
    <>
      <Modal show={userService.isExpired()} onHide={() => {}}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalCenterTitle">
            <FormattedMessage id="app.youMustReconnect" />
          </h5>
        </Modal.Header>
        <Modal.Body>
          {alert.message && (
            <div className={`alert fade show ${alert.type}`} role="alert">
              <FormattedMessageFixed id={alert.message} />
            </div>
          )}
          <LoginForm showRegister={false} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            id="logout"
            className="btn btn-secondary"
            onClick={() => dispatch(userActions.logout())}
            data-dismiss="modal"
          >
            <FormattedMessage id="app.logout" />
          </button>
        </Modal.Footer>
      </Modal>

      <header>
        <div className="aa-header">
          <nav className="navbar navbar-expand-sm navbar-transparent pb-3">
            <div className="container">
              <Link to="/">
                <img
                  className="img-fluid pr-3 aa-logo-img"
                  src="/images/codepeecker-logo.png"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample03"
                aria-controls="navbarsExample03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars"></i>
                </span>
              </button>

              <div className="navbar-collapse collapse" id="navbarsExample03">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="/"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span
                        className={`flag-icon flag-icon-${
                          intl.locale === "fr" ? "fr" : "us"
                        }`}
                      />
                    </a>
                    <div className="dropdown-menu">
                      <Link
                        className="dropdown-item"
                        to={currentPathWithLocale("fr")}
                        onClick={(e) => setLocale("fr")}
                      >
                        <span className="flag-icon flag-icon-fr"> </span>{" "}
                        Français
                      </Link>
                      <Link
                        className="dropdown-item"
                        to={currentPathWithLocale("en")}
                        onClick={(e) => setLocale("en")}
                      >
                        <span className="flag-icon flag-icon-us"> </span>{" "}
                        English
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={router.path("faq", intl.locale)}
                    >
                      <FormattedMessage id="app.faq" />
                    </Link>
                  </li>
                  <PrivateComponent>
                    <li className="nav-item">
                      <Link className="nav-link" to="/operation/create">
                        <FormattedMessage id="app.create" />
                      </Link>
                    </li>
                  </PrivateComponent>
                  <PrivateComponent>
                    <li className="nav-item">
                      <Link className="nav-link" to="/operation">
                        <FormattedMessage id="app.list" />
                      </Link>
                    </li>
                  </PrivateComponent>
                  <PublicComponent>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={router.path("login", intl.locale)}
                      >
                        <FormattedMessage id="app.login" />
                      </Link>
                    </li>
                  </PublicComponent>
                  <PublicComponent>
                    <li className="nav-item">
                      <Link className="nav-link" to="/register">
                        <FormattedMessage id="app.register" />
                      </Link>
                    </li>
                  </PublicComponent>

                  {user.id ? (
                    <PrivateComponent>
                      <li className="nav-item dropdown">
                        <Link
                          className="dropdown-toggle nav-link"
                          data-toggle="dropdown"
                          to="/"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <Me user={user} />
                        </Link>
                        <div className="dropdown-menu">
                          <Link className="dropdown-item" to="/code/get-codes">
                            <FormattedMessage id="app.getCode" />
                          </Link>
                          <Link className="dropdown-item" to="/payment">
                            <FormattedMessage id="app.invoices" />
                          </Link>
                          <Link className="dropdown-item" to="/user/profile">
                            <FormattedMessage id="app.profile" />
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/password/get-new-password"
                          >
                            <FormattedMessage id="app.changePassword" />
                          </Link>
                          <div className="dropdown-divider"></div>
                          <Link className="dropdown-item" to="/logout">
                            <FormattedMessage id="app.logout" />
                          </Link>
                        </div>
                      </li>
                    </PrivateComponent>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
