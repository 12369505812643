import React from "react";

import "./Header.css";
import { FormattedMessage } from "react-intl";

const Header = () => {
  return (
    <div className="row">
      <div className="col-md-12 header">
        <h1 className="h1">
          <FormattedMessage
            id="app.page.password.generatePassword.title"
            defaultMessage="Générateur de mots de passe"
          />
        </h1>
        <div className="col-md-12">
          <h4>
            <FormattedMessage
              id="app.page.password.generatePassword.text"
              defaultMessage="Créez vos mots de passe à l'aide de notre générateur"
            />
            .
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Header;
