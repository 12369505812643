import React from "react";
import PropTypes from "prop-types";

import "./CheckBox.css";
import FormattedMessageFixed from "../../../../../FormattedMessageFixed";

const CheckBox = (props) => {
  const { label, value, checked, name, onChange, disabled, id } = props;

  return (
    <>
      <div className="col-md-3">
        <label className="container">
          {id && (
            <h1>
              {label && (
                <FormattedMessageFixed id={id} defaultMessage={label} />
              )}
            </h1>
          )}
          <input
            type="checkbox"
            name={name}
            checked={checked}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className="checkbox-input"
          />
          <span
            className="checkmark"
            style={{ opacity: disabled ? "0.7" : "" }}
          ></span>
        </label>
      </div>
    </>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
