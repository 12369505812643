export const userService = {
  login,
  logout,
  isAuthenticated,
  getToken,
  isExpired,
  setExpired,
};

export const TOKEN_KEY = "auth-token";
export const TOKEN_EXPIRED = "token-expired";

function login(username, password) {
  const HEADERS = new Headers();
  HEADERS.append("Accept", "application/json");
  HEADERS.append("Content-Type", "application/json");

  const API_URL = process.env.REACT_APP_BASE_URL || "";

  const init = {
    headers: HEADERS,
    method: "POST",
    body: JSON.stringify({ email: username, password: password }),
  };

  return fetch(`${API_URL}/login`, init)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }

      return response.json();
    })
    .then((data) => {
      // login successful if there's a jwt token in the response
      localStorage.setItem(TOKEN_KEY, data.token);
      userService.setExpired(false);

      return data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_EXPIRED);
}

function isAuthenticated() {
  return !!localStorage.getItem(TOKEN_KEY);
}

function isExpired() {
  return localStorage.getItem(TOKEN_EXPIRED) === "true";
}

function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

function setExpired(isExpired) {
  localStorage.setItem(TOKEN_EXPIRED, isExpired);
}
