import React from "react";

import { alertActions } from "../../Actions";
import Input from "../Form/Input";
import Form from "../../Form";
import { history, store } from "../../Helper";
import ModelManager from "../../ModelManager";
import { userService } from "../../Service";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export class ResetPasswordForm extends Form {
  constructor(props) {
    const element = {
      "@id": "/api/request_password_histories",
      plainPassword: "",
      repeatPassword: "",
      requestedAt: null,
      email: null,
    };

    super(props, element);

    this.getRequestPasswordHistory = this.getRequestPasswordHistory.bind(this);
  }

  componentWillMount() {
    this.getRequestPasswordHistory();
  }

  getRequestPasswordHistory() {
    this.setState({ isLoading: true });

    ModelManager.fetchList(
      "/api/request_password_histories",
      { token: this.props.token },
      true
    )
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((res) => {
        if (res.getCount() === 0) {
          throw new Error("Element not found");
        }

        const element = res.getMembers()[0];
        element.plainPassword = "";
        element.repeatPassword = "";
        this.setState({ element });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  submit(e) {
    e.preventDefault();

    let violations = [];

    if (this.state.element.plainPassword === "") {
      violations.push({
        propertyPath: "plainPassword",
        message: "The password field is required.",
      });
    }

    if (
      this.state.element.plainPassword !== this.state.element.repeatPassword
    ) {
      violations.push({
        propertyPath: "plainPassword",
        message: "The password fields must match.",
      });
    }

    if (violations.length === 0) {
      ModelManager.save(this.state.element)
        .then((element) => {
          this.setState({ element });
          history.push(
            userService.isAuthenticated() ? "/user/profile" : "/login"
          );
          store.dispatch(
            alertActions.success("Your password has been change.")
          );
        })
        .catch((error) => {
          error.violations
            ? this.mapErrors(error.violations)
            : this.cleanErrors();
        });
    } else {
      this.mapErrors(violations);
    }
  }

  render() {
    return (
      <div className="container">
        <div className="page-content">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <FormattedMessage id="app.page.resetPasswordForm.changePassword" />
              </li>
            </ol>
          </nav>

          <h1>
            <FormattedMessage id="app.page.resetPasswordForm.changePassword" />
          </h1>
          {false === this.state.isLoading ? (
            <div>
              {this.state.error ? (
                <p>
                  <FormattedMessage id="app.page.resetPasswordForm.tokenNotFound" />
                </p>
              ) : (
                <form id="login-form" noValidate>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <Input
                        name="plainPassword"
                        label="form.label.password"
                        ref="plainPassword"
                        type="password"
                        value={this.state.element.plainPassword}
                        errors={this.state.errors.plainPassword}
                        updateElement={this.updateElement}
                        required={true}
                      />

                      <Input
                        name="repeatPassword"
                        label="form.label.passwordRepeat"
                        ref="repeatPassword"
                        type="password"
                        value={this.state.element.repeatPassword}
                        errors={this.state.errors.repeatPassword}
                        updateElement={this.updateElement}
                        required={true}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={this.submit}
                  >
                    <FormattedMessage id="app.page.resetPasswordForm.submit" />
                  </button>

                  <small className="form-text text-muted">
                    (*){" "}
                    <FormattedMessage id="app.page.resetPasswordForm.fieldRequired" />
                    .
                  </small>
                </form>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
