import React from "react";
import { Redirect, Route } from "react-router-dom";
import { userService } from "../Service";

class PublicRoute extends React.Component {
  render() {
    return !userService.isAuthenticated() ? (
      <Route {...this.props} />
    ) : (
      <Redirect to={{ pathname: "/", state: {} }} />
    );
  }
}

export default PublicRoute;
