import React, { useContext, lazy, Suspense, useEffect } from "react";
import { Link, Route, Router, Switch } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./Component/PrivateRoute";
import Home from "./Component/Home/Home";
import { history } from "./Helper";
import { userActions } from "./Actions";
import PublicRoute from "./Component/PublicRoute";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet/es/Helmet";
import FormattedMessageFixed from "./FormattedMessageFixed";
import { LoginForm } from "./Component/Login/LoginForm";
import { GetNewPasswordForm } from "./Component/Password/GetNewPasswordForm";
import { ResetPasswordForm } from "./Component/Password/ResetPasswordForm";
import { RegisterForm } from "./Component/Register/RegisterForm";
import { OperationForm } from "./Component/Operation/OperationForm";
import { ProfileForm } from "./Component/User/ProfileForm";
import NotFound from "./Component/NotFound/NotFound";
import { RouteContext } from "./Router/RouteContext";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./Router/ScrollToTop";
import Header from "./Component/Layout/Header";
import Generator from "./Component/Password/Generator/Generator";

const Logout = lazy(() => import("./Component/Login/Logout"));
const Confirm = lazy(() => import("./Component/Login/Confirm"));
const GetCodeForm = lazy(() => import("./Component/Code/GetCode"));
const Payment = lazy(() => import("./Component/Payment/Payment"));
const GenerationShow = lazy(() =>
  import("./Component/Generation/GenerationShow")
);
const LegalNotice = lazy(() => import("./Component/LegalNotice/LegalNotice"));
const TermsOfUse = lazy(() => import("./Component/TermsOfUse/TermsOfUse"));
const Faq = lazy(() => import("./Component/Faq/Faq"));
const PaymentList = lazy(() => import("./Component/Payment/PaymentList"));
const OperationShow = lazy(() => import("./Component/Operation/OperationShow"));
const OperationList = lazy(() => import("./Component/Operation/OperationList"));
const Content = lazy(() => import("./Component/Content/Content"));

function camelize(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

function toSnakeCase(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\s]/g, "")
    .replace(/\s+/g, " ")
    .toLowerCase()
    .split(" ")
    .join("-");
}

function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
}

export function App() {
  const seoLinks = [
    "Générateur de code aléatoires uniques",
    "Générateur code promo",
    "Générateur QR code",
    "Générateur flash code",
    "Générateur coupon de réduction",
    "Générateur remise",
    "Générateur cartes cadeaux",
    "Générateur carte fidélité",
    "Générateur serial key",
    "Générateur code réduction",
    "Générateur de mot de passe",
  ];

  const dispatch = useDispatch();
  const router = useContext(RouteContext);
  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    dispatch(userActions.getConnectedUser());
  }, [dispatch]);

  const links = seoLinks.map((link, index) => {
    return (
      <li key={index}>
        <a href={`/content/${toSnakeCase(link)}`}>
          <FormattedMessageFixed
            id={`app.seo.${camelize(link)}`}
            defaultMessage={link}
          />
        </a>
      </li>
    );
  });

  const resetPasswordForm = ({ match }) => (
    <div>
      <ResetPasswordForm token={match.params.token} />
    </div>
  );

  const Login = () => (
    <div className="container">
      <div className="page-content">
        <LoginForm />
      </div>
    </div>
  );

  const regexUUID =
    "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";

  return (
    <div>
      <Helmet>
        <title>CodePeecker - Le générateur de codes uniques</title>
        <meta
          name="title"
          content="CodePeecker - Le générateur de codes uniques"
        />
        <meta
          name="description"
          content="Un générateur de codes, simple, rapide et personnalisable.
    CodePeecker, le générateur de codes peut être utilisé pour les codes promotionnels, codes cadeaux, les numéros de série et beaucoup d'autres utilisations. Si vous avez besoin de codes uniques, essayez CodePeecker, le générateur de codes uniques."
        />
      </Helmet>

      <Router history={history}>
        <div>
          <ScrollToTop />
          <Header />
          {alert.message && (
            <div className={`alert fade show ${alert.type}`} role="alert">
              <FormattedMessageFixed id={alert.message} />
            </div>
          )}

          <Switch>
            <Route exact path={router.paths("home")} component={Home} />
            <Route
              exact
              path="/content/:slug"
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Content slug={props.match.params.slug} {...props} />
                </Suspense>
              )}
            />
            <PublicRoute exact path={router.paths("login")} component={Login} />
            <Route
              exact
              path={router.paths("legal-notice")}
              component={WaitingComponent(LegalNotice)}
            />
            <Route
              exact
              path={router.paths("faq")}
              component={WaitingComponent(Faq)}
            />
            <Route
              exact
              path={router.paths("terms-of-use")}
              component={WaitingComponent(TermsOfUse)}
            />
            <PublicRoute
              exact
              path={router.paths("register")}
              component={RegisterForm}
            />
            <PublicRoute
              exact
              path={router.paths("confirm")}
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Confirm
                    confirmationToken={props.match.params.confirmationToken}
                  />
                </Suspense>
              )}
            />
            <Route
              exact
              path={router.paths("get-new-password")}
              component={GetNewPasswordForm}
            />
            <Route
              exact
              path={router.paths("generate-password")}
              component={Generator}
            />
            <Route
              exact
              path={router.paths("reset-password")}
              component={resetPasswordForm}
            />
            <PrivateRoute
              exact
              path="/logout"
              component={WaitingComponent(Logout)}
            />
            <PrivateRoute exact path="/user/profile" component={ProfileForm} />
            <PrivateRoute
              exact
              path="/code/get-codes"
              component={WaitingComponent(GetCodeForm)}
            />
            <PrivateRoute
              exact
              path="/code/get-codes/:quantity"
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <GetCodeForm {...props} />
                </Suspense>
              )}
            />
            <PrivateRoute
              exact
              path={"/payment/payment/:requestCodeId(" + regexUUID + ")"}
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Payment
                    requestCodeId={props.match.params.requestCodeId}
                    {...props}
                  />
                </Suspense>
              )}
            />

            <PrivateRoute
              exact
              path={"/payment"}
              component={WaitingComponent(PaymentList)}
            />
            <PrivateRoute
              exact
              path="/operation/create"
              component={OperationForm}
            />
            <PrivateRoute
              exact
              path={
                "/operation/:operationId(" +
                regexUUID +
                ")/generation/:generationId(" +
                regexUUID +
                ")"
              }
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <GenerationShow
                    operationId={props.match.params.operationId}
                    generationId={props.match.params.generationId}
                  />
                </Suspense>
              )}
            />
            <PrivateRoute
              exact
              path={"/operation/:operationId(" + regexUUID + ")"}
              render={(props) => (
                <Suspense fallback={<div>Loading...</div>}>
                  <OperationShow
                    operationId={props.match.params.operationId}
                    {...props}
                  />
                </Suspense>
              )}
            />
            <PrivateRoute
              exact
              path="/operation"
              component={WaitingComponent(OperationList)}
            />

            <Route component={NotFound} />
          </Switch>

          <div
            className="aa-download-section section bg-gradient"
            id="download"
          >
            <div className="container">
              <div className="h2 text-center text-title text-white pb-5">
                <FormattedMessage id="app.UseAwesomeDigicodeNow" />
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <Link className="aa-apple" to="/register">
                    <div className="card">
                      <div className="row pb-3">
                        <div className="col-lg-3 col-md-12 aa-download-icon">
                          <i
                            className="pt-4 fa fa-address-card fa-4x"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-9 col-md-12 aa-download-icon-detail">
                          <div className="h4 pb-1">
                            <FormattedMessage id="app.register" />
                          </div>
                          <p className="text-muted">
                            <FormattedMessage id="app.createYourAccount" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-md-6 col-sm-12">
                  <Link className="aa-apple" to="/login">
                    <div className="card">
                      <div className="row pb-3">
                        <div className="col-lg-3 col-md-12 aa-download-icon">
                          <i
                            className="pt-4 fa fa-user fa-4x"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-9 col-md-12 aa-download-icon-detail">
                          <div className="h4 pb-1">
                            <FormattedMessage id="app.login" />
                          </div>
                          <p className="text-muted">
                            <FormattedMessage id="app.login" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer-black aa-footer">
            <div className="container py-5">
              <div className="row text-center">
                <div className="col-md-12">
                  <p className="mt-3">
                    <Link to="/legal-notice">
                      <FormattedMessage id="app.legalNotice" />
                    </Link>
                    &nbsp;-{" "}
                    <Link to="/terms-of-use">
                      <FormattedMessage id="app.termsOfUse" />
                    </Link>
                    &nbsp;- Copyright &copy; CodePeecker.{" "}
                    <FormattedMessage id="app.allRightsReserved" />.
                  </p>
                </div>
                <div className="col-md-12">
                  <div className="mt-3">
                    <ul>{links}</ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </div>
  );
}
