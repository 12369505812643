import React from "react";
import { Route } from "react-router-dom";
import { userService } from "../Service";

class PublicComponent extends React.Component {
  render() {
    return userService.isAuthenticated() ? "" : <Route {...this.props} />;
  }
}

export default PublicComponent;
