import React from "react";
import ModelManager from "./ModelManager";

class ElementForm extends React.Component {
  constructor(props, element, requiredFields = []) {
    super(props);

    this.state = {
      isLoading: false,
      element: element,
      errors: {},
      requiredFields: requiredFields,
    };

    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.submit = this.submit.bind(this);
    this.mapErrors = this.mapErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
    this.isRequired = this.isRequired.bind(this);
    this.updateElement = this.updateElement.bind(this);
  }

  isRequired(name) {
    return this.state.requiredFields.includes(name);
  }

  updateElement(name, value) {
    const element = { ...this.state.element };
    const errors = { ...this.state.errors };

    if (!this.state.element.hasOwnProperty(name)) {
      return;
    }

    errors[name] = [];

    element[name] = value;
    this.setState({ element, errors });
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    const element = { ...this.state.element };

    if (!this.state.element.hasOwnProperty(name)) {
      return;
    }

    element[name] =
      event.target.type === "number" ||
      (event.target.dataset.type && event.target.dataset.type === "number")
        ? parseInt(value, 10)
        : value;
    this.setState({ element });
  }

  getValidationState(name) {
    if (this.hasError(name) && this.state.errors[name]) {
      return "error";
    }

    return null;
  }

  submit(e) {
    e.preventDefault();

    ModelManager.save(this.state.element)
      .then((element) => {
        this.setState({ element });
      })
      .catch((error) => {
        error.violations
          ? this.mapErrors(error.violations)
          : this.cleanErrors();
      });
  }

  mapErrors(violations) {
    let errors = {};

    for (let i = 0; i < violations.length; i++) {
      const violation = violations[i];

      if (!errors[violation.propertyPath]) {
        errors[violation.propertyPath] = [];
      }
      errors[violation.propertyPath].push(violation.message);
    }

    this.setState({ errors: errors });
  }

  cleanErrors() {
    this.setState({ errors: {} });
  }
}

export default ElementForm;
