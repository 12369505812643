export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  RECONNECT: "USERS_RECONNECT",

  GETCONNECTEDUSER_REQUEST: "USERS_GETCONNECTEDUSER_REQUEST",
  GETCONNECTEDUSER_SUCCESS: "USERS_GETCONNECTEDUSER_SUCCESS",
  GETCONNECTEDUSER_FAILURE: "USERS_GETCONNECTEDUSER_FAILURE",
};
