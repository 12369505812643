import React from "react";
import Header from "./header/Header";
import Display from "./display/Display";

const Generator = () => {
  return (
    <div className="password-generator">
      <Header />
      <Display />
    </div>
  );
};

export default Generator;
