import React from "react";
import { Redirect, Route } from "react-router-dom";
import { userService } from "../Service";

function PrivateRoute(props) {
  userService.url = props.location.pathname;
  return userService.isAuthenticated() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  );
}

export default PrivateRoute;
