import { combineReducers } from "redux";

import { authentication } from "./AuthenticationReducer";
import { user } from "./UsersReducer";
import { alert } from "./AlertReducer";
import { intlReducer } from "react-intl-redux";

const rootReducer = combineReducers({
  authentication,
  user,
  alert,
  intl: intlReducer,
});

export default rootReducer;
