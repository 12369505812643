import { userConstants } from "../Constant";
import { userService } from "../Service";
import { alertActions } from "./";
import { history } from "../Helper";
import UserModelManager from "../UserModelManager";

export const userActions = {
  login,
  logout,
  getConnectedUser,
  reconnect,
};

function getConnectedUser() {
  return (dispatch) => {
    UserModelManager.getConnectedUser().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(user) {
    return { type: userConstants.GETCONNECTEDUSER_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.GETCONNECTEDUSER_FAILURE, error };
  }
}

function reconnect() {
  return (dispatch) => {
    userService.setExpired(true);
    dispatch({ type: userConstants.RECONNECT });
  };
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        const currentLocation = userService.url ? userService.url : "/";
        if (currentLocation === "/login" || currentLocation === "/logout") {
          history.push("/");
        } else {
          history.push("/");
          history.push(currentLocation);
        }
        userService.url = null;
      },
      (error) => {
        dispatch(failure(error));
        if (userService.isExpired()) {
          dispatch({ type: userConstants.RECONNECT });
        }
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT });
    userService.logout();
    history.push("/login");
  };
}
