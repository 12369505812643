import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { store } from "./Helper";
import fr from "react-intl/locale-data/fr";
import en from "react-intl/locale-data/en";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { StripeProvider } from "react-stripe-elements";
import IntlProvider from "react-intl-redux/lib/components/IntlProvider";
import { addLocaleData } from "react-intl";
import * as serviceWorker from "./serviceWorker";
import messages_fr from "./Translations/fr.json";

const env = process.env.REACT_APP_ENV || "prod";

if ("prod" === env) {
  LogRocket.init("sks4it/digicode");
  setupLogRocketReact(LogRocket);
}

addLocaleData([...fr, ...en]);

ReactDOM.render(
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_TOKEN}>
    <Provider store={store}>
      <IntlProvider messages={messages_fr} locale="fr">
        <App />
      </IntlProvider>
    </Provider>
  </StripeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
