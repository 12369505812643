import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { alertActions, userActions } from "../Actions";
import { useDispatch } from "react-redux";

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(alertActions.clear());
    dispatch(userActions.getConnectedUser());
    window.scrollTo(0, 0);
  }, [pathname, dispatch]);

  return null;
};

export default ScrollToTop;
