import React from "react";
import ElementForm from "../../Form";
import Input from "../Form/Input";
import ModelManager from "../../ModelManager";
import { store } from "../../Helper";
import { alertActions, userActions } from "../../Actions";
import Checkbox from "../Form/Checkbox";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

class RegisterForm extends ElementForm {
  constructor(props) {
    const user = {
      "@id": "/api/users",
      email: "",
      repeatEmail: "",
      plainPassword: "",
      repeatPassword: "",
      company: null,
      address: "",
      zipcode: "",
      city: "",
      vat: null,
      country: "",
      firstname: "",
      lastname: "",
      phone: "",
      termsOfUse: false,
    };

    const requiredFields = [
      "email",
      "repeatEmail",
      "plainPassword",
      "repeatPassword",
      "address",
      "zipcode",
      "city",
      "country",
      "firstname",
      "lastname",
      "phone",
    ];

    super(props, user, requiredFields);
  }

  submit(e) {
    e.preventDefault();

    let violations = [];
    const { dispatch } = this.props;

    for (let requiredField of this.state.requiredFields) {
      if (this.state.element[requiredField] === "") {
        violations.push({
          propertyPath: requiredField,
          message: "form.error.notBlank",
        });
      }
    }

    if (this.state.element.termsOfUse === false) {
      violations.push({
        propertyPath: "termsOfUse",
        message: "form.error.termsOfUse",
      });
    }

    if (this.state.element.email !== this.state.element.repeatEmail) {
      violations.push({
        propertyPath: "email",
        message: "form.error.emailNotMatch",
      });
    }
    if (
      this.state.element.plainPassword !== this.state.element.repeatPassword
    ) {
      violations.push({
        propertyPath: "plainPassword",
        message: "form.error.passwordNotMatch",
      });
    }

    if (violations.length === 0) {
      ModelManager.save(this.state.element)
        .then((element) => {
          dispatch(
            userActions.login(
              this.state.element.email,
              this.state.element.plainPassword
            )
          );
          //store.dispatch(alertActions.success('app.message.registered.ok'));
        })
        .catch((error) => {
          store.dispatch(alertActions.error("app.message.registered.ko"));

          error.violations
            ? this.mapErrors(error.violations)
            : this.cleanErrors();
          window.scrollTo(0, 0);
        });
    } else {
      store.dispatch(alertActions.error("app.message.registered.ko"));

      this.mapErrors(violations);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="container">
        <div className="page-content">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <FormattedMessage id="app.page.register.home" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <FormattedMessage id="app.page.register.register" />
              </li>
            </ol>
          </nav>

          <h1>
            <FormattedMessage id="app.page.register.register" />
          </h1>

          <form id="login-form" noValidate>
            <h5>
              <FormattedMessage id="app.page.register.login" />
            </h5>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="email"
                  label="form.label.email"
                  ref="email"
                  type="email"
                  value={this.state.element.email}
                  errors={this.state.errors.email}
                  updateElement={this.updateElement}
                  required={this.isRequired("email")}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  name="repeatEmail"
                  label="form.label.repeatEmail"
                  type="email"
                  value={this.state.element.repeatEmail}
                  errors={this.state.errors.repeatEmail}
                  updateElement={this.updateElement}
                  required={this.isRequired("repeatEmail")}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="plainPassword"
                  label="form.label.password"
                  type="password"
                  value={this.state.element.plainPassword}
                  errors={this.state.errors.plainPassword}
                  updateElement={this.updateElement}
                  required={this.isRequired("plainPassword")}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  name="repeatPassword"
                  label="form.label.confirmYourPassword"
                  type="password"
                  value={this.state.element.repeatPassword}
                  errors={this.state.errors.repeatPassword}
                  updateElement={this.updateElement}
                  required={this.isRequired("repeatPassword")}
                />
              </div>
            </div>
            <h5>
              <FormattedMessage id="app.page.register.information" />
            </h5>

            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="firstname"
                  label="form.label.firstname"
                  type="text"
                  value={this.state.element.firstname}
                  errors={this.state.errors.firstname}
                  updateElement={this.updateElement}
                  required={this.isRequired("firstname")}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  name="lastname"
                  label="form.label.lastname"
                  type="text"
                  errors={this.state.errors.lastname}
                  value={this.state.element.lastname}
                  updateElement={this.updateElement}
                  required={this.isRequired("lastname")}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="phone"
                  label="form.label.phone"
                  type="text"
                  errors={this.state.errors.phone}
                  value={this.state.element.phone}
                  updateElement={this.updateElement}
                  required={this.isRequired("phone")}
                />
              </div>
            </div>

            <h5>
              <FormattedMessage id="app.page.register.address" />
            </h5>

            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="address"
                  label="form.label.address"
                  type="text"
                  errors={this.state.errors.address}
                  value={this.state.element.address}
                  updateElement={this.updateElement}
                  required={this.isRequired("address")}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  name="country"
                  label="form.label.country"
                  type="text"
                  errors={this.state.errors.country}
                  value={this.state.element.country}
                  updateElement={this.updateElement}
                  required={this.isRequired("country")}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="zipcode"
                  label="form.label.zipcode"
                  type="text"
                  errors={this.state.errors.zipcode}
                  value={this.state.element.zipcode}
                  updateElement={this.updateElement}
                  required={this.isRequired("zipcode")}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  name="city"
                  label="form.label.city"
                  type="text"
                  errors={this.state.errors.city}
                  value={this.state.element.city}
                  updateElement={this.updateElement}
                  required={this.isRequired("city")}
                />
              </div>
            </div>

            <h5>
              <FormattedMessage id="app.page.register.company" />
            </h5>

            <div className="form-row">
              <div className="form-group col-md-6">
                <Input
                  name="company"
                  label="form.label.company"
                  type="text"
                  errors={this.state.errors.company}
                  value={this.state.element.company}
                  updateElement={this.updateElement}
                  required={this.isRequired("company")}
                />
              </div>

              <div className="form-group col-md-6">
                <Input
                  name="vat"
                  label="form.label.intracommunityVAT"
                  type="text"
                  errors={this.state.errors.vat}
                  value={this.state.element.vat}
                  updateElement={this.updateElement}
                  required={this.isRequired("vat")}
                />
              </div>
            </div>

            <h5>
              <FormattedMessage id="app.page.register.termsOfUse" />
            </h5>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Checkbox
                  name="termsOfUse"
                  label="form.label.termsOfUse"
                  errors={this.state.errors.termsOfUse}
                  value={this.state.element.termsOfUse}
                  className="form-check-input"
                  updateElement={this.updateElement}
                  required={true}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block"
              onClick={this.submit}
            >
              <FormattedMessage id="app.page.register.register" />
            </button>

            <small className="form-text text-muted">
              (*) <FormattedMessage id="app.page.register.fieldRequired" />.
            </small>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {}

const connectedRegisterForm = connect(mapStateToProps)(RegisterForm);
export { connectedRegisterForm as RegisterForm };
