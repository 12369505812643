import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../Actions";
import Input from "../Form/Input";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      submitted: false,
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateElement = this.updateElement.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  mapErrors(violations) {
    let errors = {};

    for (let i = 0; i < violations.length; i++) {
      const violation = violations[i];

      if (!errors[violation.propertyPath]) {
        errors[violation.propertyPath] = [];
      }
      errors[violation.propertyPath].push(violation.message);
    }

    this.setState({ errors: errors });
  }

  updateElement(name, value) {
    const state = { ...this.state };

    if (!this.state.hasOwnProperty(name)) {
      return;
    }

    state[name] = value;
    state.errors[name] = [];
    this.setState(state);
  }

  handleSubmit(e) {
    e.preventDefault();

    let violations = [];

    if (this.state.username === "") {
      violations.push({
        propertyPath: "username",
        message: "form.error.notBlank",
      });
    }

    if (this.state.password === "") {
      violations.push({
        propertyPath: "password",
        message: "form.error.notBlank",
      });
    }

    if (violations.length === 0) {
      this.setState({ submitted: true });
      const { username, password } = this.state;
      const { dispatch } = this.props;
      if (username && password) {
        dispatch(userActions.login(username, password));
      }
    } else {
      this.mapErrors(violations);
    }
  }

  render() {
    const { loggingIn } = this.props;

    return (
      <div>
        <h1>
          <FormattedMessage id="app.page.loginForm.login" />
        </h1>

        <form id="login-form" onSubmit={this.handleSubmit} noValidate>
          <div className="form-group">
            <Input
              name="username"
              label="form.label.email"
              ref="username"
              type="email"
              value={this.state.username}
              placeholder="email@domain.fr"
              errors={this.state.errors.username}
              updateElement={this.updateElement}
              required="true"
            />
          </div>
          <div className="form-group">
            <Input
              name="password"
              label="form.label.password"
              ref="123456"
              type="password"
              value={this.state.password}
              placeholder="123456"
              errors={this.state.errors.password}
              updateElement={this.updateElement}
              required="true"
            />
            <small id="emailHelp" className="form-text text-muted">
              <Link to="/password/get-new-password">
                <FormattedMessage id="app.page.loginForm.iForgetPassword" />
              </Link>
            </small>
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            <FormattedMessage id="app.page.loginForm.login" />
          </button>
          {loggingIn && (
            <img
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              alt="Login"
            />
          )}

          <small className="form-text text-muted">
            (*) <FormattedMessage id="app.page.loginForm.fieldRequired" />
          </small>

          {this.props.showRegister !== false && (
            <>
              <h1>
                <FormattedMessage id="app.page.loginForm.noAccount" />
              </h1>
              <br />
              <br />
              <Link to="/register" className="btn btn-block">
                <FormattedMessage id="app.page.loginForm.register" />
              </Link>
            </>
          )}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, user } = state.authentication;
  return {
    loggingIn,
    user,
  };
}

const connectedLoginForm = connect(mapStateToProps)(LoginForm);
export { connectedLoginForm as LoginForm };
