const MEMBER_PROPERTY = "hydra:member";
const TOTAL_ITEMS_PROPERTY = "hydra:totalItems";

/**
 * Hold items of an Hydra Collection.
 */
export default class Collection {
  /**
   * Collection constructor.
   * @constructor
   *
   * @param {object} data
   */
  constructor(data) {
    for (const prop of [MEMBER_PROPERTY, TOTAL_ITEMS_PROPERTY]) {
      if (!data.hasOwnProperty(prop)) {
        throw new Error(
          `Invalid data provided for collection: missing "${prop}" property`
        );
      }
    }
    this.data = data;
  }

  /**
   * Get collection items.
   *
   * @returns {array}
   */
  getMembers() {
    return this.data[MEMBER_PROPERTY];
  }

  /**
   * Get total items count.
   *
   * @returns {int}
   */
  getTotalItems() {
    return this.data[TOTAL_ITEMS_PROPERTY];
  }

  /**
   * Get items count for this collection.
   *
   * @returns {int}
   */
  getCount() {
    return this.getMembers().length;
  }
}
