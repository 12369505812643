import React from "react";
import PropTypes from "prop-types";
import FormattedMessageFixed from "../../../../../FormattedMessageFixed";

const Button = (props) => {
  const { label, className, iconClass, handleClick, id } = props;

  return (
    <>
      <button className={className} label={label} onClick={handleClick}>
        <i className={iconClass}></i>
        {label && <FormattedMessageFixed id={id} defaultMessage={label} />}
      </button>
    </>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Button;
