import Encoding from "../Model/Encoding";
import EndOfLine from "../Model/EndOfLine";

export const ENCODING_UTF8 = 0;
export const ENCODING_LATIN1 = 1;

export const EOL_LINUX = 0;
export const EOL_WINDOWS = 1;

export default class Operation {
  static getEncodingName(id) {
    switch (id) {
      case ENCODING_UTF8:
        return "UTF-8";

      case ENCODING_LATIN1:
        return "LATIN-1";

      default:
        return "N/A";
    }
  }

  static getEndOfLineName(id) {
    switch (id) {
      case EOL_LINUX:
        return "Linux";

      case EOL_WINDOWS:
        return "Windows";

      default:
        return "N/A";
    }
  }
}

export const encodingOptions = [
  new Encoding(ENCODING_UTF8, Operation.getEncodingName(ENCODING_UTF8)),
  new Encoding(ENCODING_LATIN1, Operation.getEncodingName(ENCODING_LATIN1)),
];

export const eofOptions = [
  new EndOfLine(EOL_LINUX, "LINUX"),
  new EndOfLine(EOL_WINDOWS, "WINDOWS"),
];
