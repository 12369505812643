import { userConstants } from "../Constant";

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.GETCONNECTEDUSER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETCONNECTEDUSER_SUCCESS:
      return action.user;
    case userConstants.GETCONNECTEDUSER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
