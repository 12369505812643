import React from "react";
import ElementForm from "../../Form";
import Input from "../Form/Input";
import ModelManager from "../../ModelManager";
import { history, store } from "../../Helper";
import { alertActions } from "../../Actions";
import UserModelManager from "../../UserModelManager";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

class ProfileForm extends ElementForm {
  constructor(props) {
    const user = {
      "@id": "/api/users",
      id: "",
      email: "",
      repeatEmail: "",
      company: "",
      address: "",
      zipcode: "",
      city: "",
      vat: "",
      country: "",
      firstname: "",
      lastname: "",
      phone: "",
    };

    const requiredFields = [
      "email",
      "repeatEmail",
      "address",
      "zipcode",
      "city",
      "country",
      "firstname",
      "lastname",
      "phone",
    ];

    super(props, user, requiredFields);
  }

  componentWillMount() {
    this.getProfile();
  }

  getProfile() {
    this.setState({ isLoading: true });

    UserModelManager.getConnectedUser()
      .finally(() => {
        this.setState({ isLoading: false });
      })
      .then((element) => {
        element["@id"] = "/api/users/" + element.id;
        element.repeatEmail = element.email;
        this.setState({ element });
      });
  }

  submit(e) {
    e.preventDefault();

    let violations = [];

    for (let requiredField of this.state.requiredFields) {
      if (this.state.element[requiredField] === "") {
        violations.push({
          propertyPath: requiredField,
          message: "form.error.notBlank",
        });
      }
    }

    if (this.state.element.email !== this.state.element.repeatEmail) {
      violations.push({
        propertyPath: "email",
        message: "form.error.emailNotMatch",
      });
    }

    if (violations.length === 0) {
      this.setState({ isLoading: true });
      ModelManager.save(this.state.element)
        .finally(() => this.setState({ isLoading: false }))
        .then((element) => {
          element.repeatEmail = element.email;
          this.setState({ element });
          history.push("/user/profile");
          store.dispatch(alertActions.success("app.message.profile.ok"));
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          store.dispatch(alertActions.error("app.message.profile.ko"));
          error.violations
            ? this.mapErrors(error.violations)
            : this.cleanErrors();
        });
    } else {
      window.scrollTo(0, 0);
      store.dispatch(alertActions.error("app.message.profile.ko"));
      this.mapErrors(violations);
    }
  }

  render() {
    return (
      <div className="container">
        <div className="page-content">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <FormattedMessage id="app.page.profileForm.home" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <FormattedMessage id="app.page.profileForm.profile" />
              </li>
            </ol>
          </nav>

          <h1>
            <FormattedMessage id="app.page.profileForm.myProfile" />
          </h1>

          {false === this.state.isLoading ? (
            <form id="login-form" noValidate>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <Input
                    name="email"
                    label="form.label.email"
                    ref="email"
                    type="email"
                    value={this.state.element.email}
                    errors={this.state.errors.email}
                    updateElement={this.updateElement}
                    required={this.isRequired("email")}
                    disabled={true}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    name="repeatEmail"
                    label="form.label.confirmEmail"
                    type="email"
                    value={this.state.element.repeatEmail}
                    errors={this.state.errors.repeatEmail}
                    updateElement={this.updateElement}
                    required={this.isRequired("repeatEmail")}
                    disabled={true}
                  />
                </div>
              </div>

              <h5>
                <FormattedMessage id="app.page.profileForm.myInformation" />
              </h5>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <Input
                    name="firstname"
                    label="form.label.firstname"
                    type="text"
                    value={this.state.element.firstname}
                    errors={this.state.errors.firstname}
                    updateElement={this.updateElement}
                    required={this.isRequired("firstname")}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    name="lastname"
                    label="form.label.lastname"
                    type="text"
                    errors={this.state.errors.lastname}
                    value={this.state.element.lastname}
                    updateElement={this.updateElement}
                    required={this.isRequired("lastname")}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <Input
                    name="phone"
                    label="form.label.phone"
                    type="text"
                    errors={this.state.errors.phone}
                    value={this.state.element.phone}
                    updateElement={this.updateElement}
                    required={this.isRequired("phone")}
                  />
                </div>
              </div>

              <h5>
                <FormattedMessage id="app.page.profileForm.myAddress" />
              </h5>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <Input
                    name="address"
                    label="form.label.address"
                    type="text"
                    errors={this.state.errors.address}
                    value={this.state.element.address}
                    updateElement={this.updateElement}
                    required={this.isRequired("address")}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    name="country"
                    label="form.label.country"
                    type="text"
                    errors={this.state.errors.country}
                    value={this.state.element.country}
                    updateElement={this.updateElement}
                    required={this.isRequired("country")}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <Input
                    name="zipcode"
                    label="form.label.zipcode"
                    type="text"
                    errors={this.state.errors.zipcode}
                    value={this.state.element.zipcode}
                    updateElement={this.updateElement}
                    required={this.isRequired("zipcode")}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    name="city"
                    label="form.label.city"
                    type="text"
                    errors={this.state.errors.city}
                    value={this.state.element.city}
                    updateElement={this.updateElement}
                    required={this.isRequired("city")}
                  />
                </div>
              </div>

              <h5>
                <FormattedMessage id="app.page.profileForm.myCompany" />
              </h5>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <Input
                    name="company"
                    label="form.label.company"
                    type="text"
                    errors={this.state.errors.company}
                    value={this.state.element.company}
                    updateElement={this.updateElement}
                    required={this.isRequired("company")}
                  />
                </div>

                <div className="form-group col-md-6">
                  <Input
                    name="vat"
                    label="form.label.intracommunityVAT"
                    type="text"
                    errors={this.state.errors.vat}
                    value={this.state.element.vat}
                    updateElement={this.updateElement}
                    required={this.isRequired("vat")}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-block"
                onClick={this.submit}
              >
                <FormattedMessage id="app.page.profileForm.update" />
              </button>

              <small className="form-text text-muted">
                (*) <FormattedMessage id="app.page.profileForm.fieldRequired" />
                .
              </small>
            </form>
          ) : (
            <div className="loading">
              <img src="/loading.gif" alt="loading" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const ConnectedProfileForm = connect(mapStateToProps)(ProfileForm);
export { ConnectedProfileForm as ProfileForm };
