import React from "react";
import { FormattedMessage } from "react-intl";

export default function NotFound(props) {
  return (
    <>
      <div className="page-content text-center">
        <div className="container">
          <h1>
            <FormattedMessage
              id="app.404Notfound"
              defaultMessage="404 Not found"
            />
          </h1>
          <p>
            <FormattedMessage
              id="app.pageNotFound"
              defaultMessage="Page not found"
            />
          </p>
        </div>
      </div>
    </>
  );
}
