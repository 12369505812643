import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../Reducer";

import messages_fr from "../Translations/fr.json";
import messages_en from "../Translations/en.json";

const locale = window.location.pathname.substr(1, 2) === "en" ? "en" : "fr";

const initialState = {
  intl: {
    locale,
    message: locale === "fr" ? messages_fr : messages_en,
  },
};

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunkMiddleware)
);
