import { userConstants } from "../Constant";
import { TOKEN_KEY } from "../Service";

let user = localStorage.getItem(TOKEN_KEY);
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.RECONNECT:
      return { reconnect: true };
    default:
      return state;
  }
}
