import React from "react";
import FormattedMessageFixed from "../../FormattedMessageFixed";

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      errors: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.hasError = this.hasError.bind(this);
    this.getClassName = this.getClassName.bind(this);
  }

  handleChange(event) {
    let value = event.target.value;

    if (
      event.target.type === "number" ||
      (event.target.dataset.type && event.target.dataset.type === "number")
    ) {
      value = parseInt(value, 10);
      if (isNaN(value)) {
        value = "";
      }
    }

    this.setState({ value });

    this.props.updateElement(this.props.name, value);
  }

  hasError() {
    return this.state.errors && this.state.errors.length > 0;
  }

  getClassName() {
    return this.hasError() ? "is-invalid form-control" : "form-control";
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.value,
      errors: props.errors,
    });
  }

  render() {
    return (
      <React.Fragment>
        <label htmlFor={this.props.name}>
          <FormattedMessageFixed id={this.props.label} />
          {this.props.required ? "*" : ""}
        </label>
        <input
          className={this.getClassName()}
          type={this.props.type}
          value={this.state.value}
          name={this.props.name}
          id={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        <div className="invalid-feedback">
          <ul className="errors">
            {this.hasError()
              ? this.state.errors.map((error, index) => {
                  return (
                    <li key={index + 1}>
                      <FormattedMessageFixed id={error} />
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Input;
