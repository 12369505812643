import Collection from "./Hydra/Collection";
import "whatwg-fetch";
import "url-search-params-polyfill";
import { userActions } from "./Actions";
import { addAuthHeader, store } from "./Helper";
import { userService } from "./Service";

export function getHeaders() {
  const headers = new Headers();
  headers.append("Accept", "application/ld+json");
  headers.append("Content-Type", "application/ld+json");

  if (userService.isAuthenticated()) {
    addAuthHeader(headers);
  }

  return headers;
}

export const API_URL = process.env.REACT_APP_BASE_URL || "";
const cache = new Map();

/**
 * @param url
 * @param init
 * @param asCollection
 * @returns {Promise}
 */
export function getPromise(url, init, asCollection = false, useCache = false) {
  if (useCache && cache.has(url)) {
    return Promise.resolve(cache.get(url));
  }

  return new Promise((resolve, reject) => {
    if (userService.isExpired()) {
      return reject("Session expired");
    }

    fetch(url, init)
      .then((res) =>
        res.json().then((data) => {
          if (!res.ok) {
            if (403 === res.status) {
              store.dispatch(userActions.reconnect());
            }

            return reject(data);
          }

          cache.set(url, asCollection ? new Collection(data) : data);
          resolve(cache.get(url));
        })
      )
      .catch(reject);
  });
}

export function getPromiseWithoutContent(url, init) {
  if (cache.has(url)) {
    return Promise.resolve(cache.get(url));
  }

  return new Promise((resolve, reject) => {
    fetch(url, init)
      .then((res) => {
        if (!res.ok) {
          if (403 === res.status) {
            store.dispatch(userActions.reconnect());
          }

          return reject(res);
        }

        resolve(res);
      })
      .catch(reject);
  });
}

export default class ModelManager {
  /**
   * Get a collection of models.
   *
   * @param modelName
   * @param filters
   * @returns {Promise}
   */
  static fetchList(modelName, filters = {}, useCache = false) {
    let query = "";

    if (Object.keys(filters).length) {
      const search = new URLSearchParams();

      for (const key in filters) {
        search.append(key, filters[key]);
      }

      query = `?${search.toString()}`;
    }

    const config = {
      method: "GET",
      headers: getHeaders(),
    };

    const url = `${API_URL}${modelName.toLowerCase()}${query}`;

    return getPromise(url, config, true, useCache);
  }

  /**
   * Get a collection of models.
   *
   * @param {string} modelName
   * @param {int} id
   * @returns {Promise}
   */
  static fetch(modelName, id) {
    const url = `${API_URL}${modelName.toLowerCase()}/${id}`;

    return getPromise(url, { headers: getHeaders() });
  }

  /**
   * Delete model.
   *
   * @param {string} modelName
   * @param {int} id
   * @returns {Promise}
   */
  static delete(modelName, id) {
    const url = `${API_URL}${modelName.toLowerCase()}/${id}`;

    // Clear cache
    cache.forEach(function (value, key) {
      if (key.indexOf(`${API_URL}${modelName.toLowerCase()}`) !== -1) {
        cache.delete(key);
      }
    });

    return getPromiseWithoutContent(url, {
      method: "DELETE",
      headers: getHeaders(),
    });
  }

  /**
   * Save a model
   * @param {object} model
   * @returns {Promise}
   */
  static save(model) {
    if (!model["@id"]) {
      throw new Error("Id missing");
    }

    const url = `${API_URL}${model["@id"]}`;
    const data = { ...model };

    for (const prop of ["@id", "id"]) {
      if (data.hasOwnProperty(prop)) {
        delete data[prop];
      }
    }

    const opts = {
      headers: getHeaders(),
      method: model.id ? "PUT" : "POST",
      body: JSON.stringify(data),
    };

    // Clear cache
    cache.forEach(function (value, key) {
      if (key.indexOf(url) !== -1) {
        cache.delete(key);
      }
    });

    return getPromise(url, opts);
  }
}
